.mainHeading {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
}

.cardContainer {
    display: flex;
    flex-wrap: wrap;
}

.noLocs {
    text-align: center;
    margin-top: 20%;
    font-size: 16px;
    color: gray;
}

.cardHeading {
    font-size: 18px;
    color:blue;
    font-weight: 500;
}