.circle {
  background-color: #004dcb;
  border-radius: 50%;
  height: var(--avatar-size);
  text-align: center;
  width: var(--avatar-size);
}

.initials {
  font-size: 20px;
  line-height: 1;
  position: relative;
  top: calc(var(--avatar-size) / 3.5);
  color: #fff;
}