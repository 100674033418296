.root {
}

.content {
  position: fixed;
  top: 86px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-wrap: no-wrap;
  z-index: 0;
}

.mainContentWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.innerContent {
  padding: 10px 20px;
  width: calc(100% - 40px);
  overflow-y: auto;
  background-color: #fafafa;
  height: -webkit-fill-available;
  // flex-basis: 70%;
}

.termsPolicy {
  cursor: pointer;
  color: rgb(70, 70, 207);
}

.privacyMain {
  text-align: center;
  margin-top: 5px;
  bottom: 0px;
  font-size: 12px;
}
