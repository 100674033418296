.root {
  background-color: #f7fbff;
  color: #444444;
  min-width: 300px;
  min-height: 100px;
  width: 100%;
  border-radius: 4px;
  // margin: 0px auto;
  // padding: 20px 15px;
  box-sizing: border-box;
  // box-shadow: 0px 0px 12px -6px #565656;
  display: flex;
  justify-content: space-around;
  align-items: center;

  // position: absolute;
  // top: 50%; right: 50%;
  // transform: translate(50%,-50%);
}

.leftContent {
  // background-color: #F7FBFF;
  text-align: center;
  flex-basis: 50%;
  // border-right: solid 1px #D84757;
  // padding-right: 30px;
  height: 100vh;
  // display: block;
  // padding: 20px;
  // overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    height: 120px;
    margin-bottom: 20px;
  }
}

.contentWrapper {
  padding-top: 15px;
  flex-basis: 50%;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainContentWrapper {
  min-width: 400px;
  overflow-y: auto;
}

@media (max-width: 700px) {
  .root {
    width: 100%;
    box-shadow: none;
    margin: 0px;
    height: 100vh;
    flex-direction: column;
  }

  .leftContent {
    text-align: center;
    flex-basis: 100%;
    // padding-left: 30px;
    width: 100%;
    height: 100vh;
    // height: 130px;
    border-right: none;
    // border-bottom: solid 1px #D84757;
    img {
      height: 120px;
    }
  }
  .contentWrapper {
    display: none;
    // padding-top: 15px;
    // flex-basis: 90%;
    // width: 100%;
    // padding-left: 20px;
    // padding-right: 20px;
  }

  .mainContentWrapper {
    min-width: none;
    width: 80%;
  }
}
