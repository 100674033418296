.root {

}

.forgotPassword {
    font-size: 12px;
    color: #00BAFF;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
}

.keepLoggedIn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
}