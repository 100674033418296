.root {
  display: inline-block;
}

// .label {
//   width: 100%;
//   cursor: pointer;
// }

.label {
  background-color: #d84857;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  cursor: pointer;

  & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
}