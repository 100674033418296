.root {
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 400px;
}

.keepLoggedIn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
}

.forgotPassword {
  font-size: 12px;
  color: #00baff;
  text-decoration: none;
  font-weight: 600;
}

.ssoLoginUrl {
  margin-top: 5px;
  border-top: solid 1px #dddfe1;
  padding-top: 25px;
  font-size: 14px;
  color: #001b40;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  // font-weight: 400;
}

.register {
  margin-top: 10px;
  font-size: 14px;
  color: #001b40;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  // font-weight: 400;
}

.termsPolicy {
  cursor: pointer;
  color: rgb(70, 70, 207);
}

.privacyMain {
  text-align: center;
  margin-top: 5px;
}

.privacyFirst {
  margin-bottom: 2px;
}

.signup {
  margin-left: 3px;
}

.registerWrapper {
  display: flex;
  justify-content: center;
}
