.container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.mainHeading {
    font-size: 22px;
    font-weight: 500;
}

.subHeading {
    font-size: 16px;
    font-weight: 300;
    color: grey;
    margin-top: 1%;
}

.imageContainer {
    margin-top: 5%;
}

.chip {
    background-color: antiquewhite;
    border-radius: 9px;
    width: fit-content;
    padding: 10px  18px;
    cursor: pointer;
    background-color: blue;
    color : whitesmoke;
}

.chipBox {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    flex-direction: column;
}

.message {
    font-size: 16px;
    font-weight: 500;
    color: rgb(66, 96, 215);
}