.root {
    max-width: 800px;
}

.table {
    margin-top: 30px;
    margin-bottom: 30px;
    border-collapse: collapse;
    width: 100%;
    background-color: white;
    padding: 0px;
    border-radius: 5px;
    border-spacing: 0px;
    box-shadow: 0px 0px 6px -2px #d6d6d6;
  
    th {
        border-bottom: solid 1px #EDEDED;
        padding: 10px 10px;
        text-align: left;
        color: #000000;
    }
  
    tr {
        td {
            padding: 10px 10px;
            color: #222222;
        }
    }
  
    tr:nth-child(even) {background: #f5f5f5}
    tr:nth-child(odd) {background: #FFF}
  }