.root {
    display: flex;
    align-items: center;
    line-height: 12px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.flex {
    display: flex;
    align-items: center;
}


.page {
    padding-left: 10px;
}

.text {
    padding-left: 5px;
    font-size: 14px;
    color: #666666;
    text-decoration: none;
}

.icon {
   cursor: pointer;
   color: #333333;
}

.link {
    color: #0066CC;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.link:hover {
    color: #0e67bf;
}