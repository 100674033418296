.root {
    max-width: 90%;
}

.eachPollOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.trashBtn {
    margin-left: 10px;
    cursor: pointer;
}