.root {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  font-family: inherit;
  font-size: 12px;
  line-height: 1.28571429;
  min-height: 5rem;
  border-radius: 3px;
  padding: 4px;
  outline: 0;
}

.searchWrapper:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  content: "\F002";
  color: #707070;
  position: absolute;
  left: 22px;
  top: 8px;
}

.searchWrapper input {
  box-sizing: border-box;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  padding-left: 24px;
  height: 30px;
  margin-right: 0;
}


.bordered:focus {
  border: 2px solid #4C9AFF;
}

.invalid {
  border: 2px solid #d9534f;
}

.normaltextAreaHeight {
  min-height:1rem;
  padding:2px;
  margin-top:3px;
  white-space: nowrap;
  resize:none;
}

.readonly {
  background: #eaeaea;
  pointer-events: none;
}
