.root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
 
    .root{
        flex-direction: column;
        align-items: flex-start;
    } 
}