.root {

}

.widgets {
    display: flex;
    align-items: baseline;
    // justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    // cursor: pointer;
}

.heading {
    margin-bottom: 0px;
    margin-top: 30px;
}