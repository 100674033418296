@import "shared/scss/variables.scss";

.inner {
    display: flex;
    align-items: center;
}

.left {
    justify-content: left;
}

.right {
    justify-content: right;
}

.center {
    justify-content: center;
}

.root {
    box-sizing: border-box;
    display: inline-block;
    background-color: $primary;
    border: none;
    padding: 12px 15px;
    border-radius: 6px;
    color: white;
    font-size: $fontSize;
    cursor: pointer;
    // display: flex;
    // align-items: center;

    &:hover {
        background-color: lighten($primary,10%);
    }
}

.small {
    padding: 6px 7px;
    font-size: 12px;
}

.normal {
    padding: 12px 25px;
}

.disabled {
    background-color: #c5c5c5;
    &:hover {
        background-color: lighten(#c5c5c5,10%);
    }
}

.primary {
    background-color: $primary;
    &:hover {
        background-color: lighten($primary,10%);
    }

    &.disabled {
        background-color: #c5c5c5;
        &:hover {
            background-color: lighten(#c5c5c5,10%);
        }
    }
}

.transparentWithBorder {
    background-color: transparent;
    // text-decoration: underline;
    // border-radius: 0px;
    border: solid 1px #A9B4C9;
    color: #A9B4C9;
    &:hover {
        background-color: transparent;
    }

    &.disabled {
        background-color: #c5c5c5;
        &:hover {
            background-color: lighten(#c5c5c5,10%);
        }
    }
}

.transparent {
    background-color: transparent;
    border: none;
    color: #222222;
    &:hover {
        background-color: transparent;
        border: none;
    }

    &.disabled {
        background-color: transparent;
        &:hover {
            background-color: transparent;
        }
    }
}

.white {
    background-color: white;
    // text-decoration: underline;
    // border-radius: 0px;
    // border: solid 1px #A9B4C9;
    color: #79879D;
    &:hover {
        background-color: white;
    }
}


.link {
    background-color: transparent;
    // text-decoration: underline;
    border-radius: 0px;
    color: #248cd8;
    &:hover {
        background-color: transparent;
    }
}

.linkDanger {
    background-color: transparent;
    // text-decoration: underline;
    border-radius: 0px;
    color: $red;
    &:hover {
        background-color: transparent;
    }

    &.disabled {
        background-color: #c5c5c5;
        &:hover {
            background-color: lighten(#c5c5c5,10%);
        }
    }
}

.secondary {
    background-color: $secondary;
    &:hover {
        background-color: lighten($secondary,10%);
    }

    &.disabled {
        background-color: #c5c5c5;
        &:hover {
            background-color: lighten(#c5c5c5,10%);
        }
    }
}

.secondary2 {
    background-color: #A0A0A0;
    &:hover {
        background-color: lighten(#A0A0A0,10%);
    }

    &.disabled {
        background-color: #c5c5c5;
        &:hover {
            background-color: lighten(#c5c5c5,10%);
        }
    }
}

.lightBlue {
    background-color: #00BBFF;
    &:hover {
        background-color: lighten(#00BBFF,10%);
    }

    &.disabled {
        background-color: #c5c5c5;
        &:hover {
            background-color: lighten(#c5c5c5,10%);
        }
    }
}

.cancel {
    background-color: #A0A0A0;
    &:hover {
        background-color: lighten(#A0A0A0,10%);
    }

    &.disabled {
        background-color: #c5c5c5;
        &:hover {
            background-color: lighten(#c5c5c5,10%);
        }
    }
}


.danger {
    background-color: $red;
    &:hover {
        background-color: lighten($red,10%);
    }

    &.disabled {
        background-color: #c5c5c5;
        &:hover {
            background-color: lighten(#c5c5c5,10%);
        }
    }
}

.success {
    background-color: $green;
    &:hover {
        background-color: lighten($green,10%);
    }

    &.disabled {
        background-color: #c5c5c5;
        &:hover {
            background-color: lighten(#c5c5c5,10%);
        }
    }
}