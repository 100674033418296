
.root{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.heading {
  font-size: 30px;
  font-weight: bold;
}

.subheading {
  font-size: 32px;
}

.file-picker {

  margin-top: 30px;
  margin-bottom: 20px;
  width: 50%;
  height: 300px;
  border: 2px solid gray;
  border-radius: 10px;
  text-align: center;
  font-size: 16px;
  color: gray;
  width: 50%;
  height: 300px;
  text-align: center;
  color: gray;

  &-label {
    display: block;
  }

  &-placeholder {
    margin-bottom: 16px;
    font-size: 20px;
    margin-top:100px;
  }

  &-button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    font-size: 20px;
    border-radius: 10px;
    cursor: pointer;
    &:disabled {
      background-color: gray;
    }
  }
}