.overlay {
    position: fixed;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
}

.ddWrapper {
    box-sizing: border-box;
    position: relative;
    // width: 222px;
    font-size: 1rem;
    user-select: none;

    .ddItem {
        box-sizing: border-box;
        padding: 10px 10px;
        min-width: 180px;
        cursor: pointer;

        &:hover {
            background-color: #f5f5f5;
        }
    }

    .ddHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        // border: 1px solid rgb(223, 223, 223);
        // border-radius: 3px;
        // background-color: white;
        line-height: 38px;
        cursor: default;
        cursor: pointer;

        span {
            margin-right: 10px;
            line-height: normal;
        }
    }

    .ddList {
        position: absolute;
        z-index: 10;
        // right: 0px;
        top: 40px;
        /* width: 100%; */
        // max-height: 215px;
        overflow-y: auto;
        border: 1px solid #dfdfdf;
        border-radius: 3px;
        box-shadow: 0 0px 5px 0px #e8e8e8;
        background-color: white;
        // font-weight: 700;
        text-align: left;
        -webkit-overflow-scrolling: touch;

        .ddScrollList {
            overflow-y: scroll;
            max-height: 215px;
            // padding: 15px 10px;
        }
    }
}