.root {

}

.error404 {
    font-size: 50px;
    color: chocolate;
}

.message {
    font-size: 20px;
    color: chocolate;
}