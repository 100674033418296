:root {
  --avatar-size: 3rem;
}

.eventTrackingCard {
  padding: 10px 20px;
  border: 1px solid #ededed;
  background-color: #ededed;
  border-radius: 4px;
  border-radius: 4px;
  margin: 0px 5px;
  margin-top: 10px;
}

.chartInfo {
  display: grid;
  justify-items: flex-start;
  align-items: center;
  margin-bottom: 25px;
}

.circle {
  background-color: #004dcb;
  border-radius: 50%;
  height: var(--avatar-size);
  text-align: center;
  width: var(--avatar-size);
}

.initials {
  font-size: 20px;
  line-height: 1;
  position: relative;
  top: calc(var(--avatar-size) / 3.5);
  color: #fff;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.locationLabel {
  border: 1px solid #a0a0a0;
  border-radius: 4px;
  padding: 2px;
  font-size: 12px;
  margin-right: 2px;
  margin-bottom: 5px;
  color: #787676;
  display: inline-flex;
}

.csvBtn {
  text-decoration: none;
  font-size: 14px;
}

.pageHeadingWrapper {
  display: flex;
  justify-content: space-between;
}

.pageHeading {
  margin: 20px 0px 0px 0px;
}

.btnWrapper {
  padding: 14px 20px;
  padding-bottom: 0;
}

.contactSection {
  margin: 0;
  display: flex;
  justify-content: space-between;
}
