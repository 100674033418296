@import "shared/scss/variables.scss";

$textColor:#A9B4C9;//#ffffff; //#333;
$red: #004DCB;
$bgColor: #001A41;

.root {
    background-color: $bgColor;//$primary;//white;
    box-sizing: border-box;
    width: 260px;
    height:100%;
    margin: 0px;
    padding: 0px;
    color: $primary;
    overflow-y: auto;
    transition: width .5s, height 4s;
}

ul.menu {
    margin: 0px;
    padding: 20px 0px 0px 0px;
    list-style-type: none;
    color: $textColor;
    font-size: 15px;
    box-sizing: border-box;

    @media screen and (max-width: 500px) {
     display: flex;
     flex-direction: column;  
     align-items: center;
    }
    
    li {
        box-sizing: border-box;
        padding: 7px 16px;
        margin: 10px;
        // border-bottom: solid 1px #C6464D;
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;

        a {
            color: $textColor;
            text-decoration: none;
            display:flex;
            align-items: center;
        }

        &.selected {
            box-sizing: border-box;
            background-color: $red;
            color: white;
            padding: 12px 16px;
            border-radius: 5px;

            cursor: default;
            a {
                color: white;
            }
        }
    }
}

.menuIcon {
    margin-right: 5px;
}