.root {

}

.eventTrackingCard {
    padding: 0px 20px;
    border: 1px solid #EDEDED;
    width: 100%;
    border-radius: 4px;
    background-color: #f8f8f8;
}

.locationLabel {
    border: 1px solid #a0a0a0;
    border-radius: 4px;
    padding: 2px;
    font-size: 12px;
    margin-right: 2px;
    margin-bottom: 5px;
    color: #787676;
    display: inline-flex;
}