.root {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 100%;
  overflow-x: scroll;
}

.table {
  border-collapse: collapse;
  width: 100%;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  border-spacing: 0px;
  box-shadow: 0px 0px 6px -2px #d6d6d6;

  th {
    border-bottom: solid 1px #ededed;
    padding: 10px;
    text-align: left;
    color: #000000;
    min-width: 100px;
  }

  tr {
    td {
      padding: 10px 10px 0px;
      color: #222222;
    }
  }

  tr:nth-child(even) {
    background: #f5f5f5;
  }
  tr:nth-child(odd) {
    background: #fff;
  }
}

.pagination {
  margin: 16px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 500px;
  max-width: 100%;
  .left {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      margin-left: 10px;
      margin-right: 5px;
    }

    button {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.clear_icon {
  margin: 0.64em 0 0 -2.8em;
  cursor: pointer;
}

.search_input {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  font-family: inherit;
  font-size: 12px;
  line-height: 1.28571429;
  height: 40px;
  border-radius: 3px;
  padding: 4px;
  outline: 0;
  border-radius: 4px;
}

// @media screen and (max-width: 360px) {
//   .page {
//     display: flex;
//   }
//   .table {
//     width: 70% !important;
//   }
// }


