.cardDiv {
    margin-bottom: 20px;
    display: flex;
    align-items: baseline;
    width: 100%;
    flex-wrap: wrap;
}

.cardStyle {
    color: #d84856;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 48px;
}

.flex{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 500px) {
        flex-direction: column;
        text-align: center;
    }
}

.headingFlex{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.planCalculatorLink {
    margin-top: 5px;
}