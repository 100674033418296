.root {
    max-width: 400px;
}

.locationLabel {
    border: 1px solid #a0a0a0;
    border-radius: 2px;
    padding: 6px;
    font-size: 12px;
    margin-right: 2px;
    margin-bottom: 5px;
    color: #787676;
    display: inline-flex;
    cursor: pointer;
}

.fileContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inputFile {
    border: 1px solid #ccc;
    padding: 5px;
    margin-left: 5px;
    border-radius: 2px;
    font-size: 16px;
}

.redLabel {
    font-size: 12px;
    color: red;
    padding-top: 5px;
}

.cursorPointer {
    cursor: pointer;
}

.uploadBtn {
    .label {
        background-color: #d84857;
        display: inline-block;
        padding: 5px 10px;
        border-radius: 4px;
        color: white;
        font-weight: bold;
        cursor: pointer;

        &>div {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}


.root{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.heading {
    font-size: 30px;
    font-weight: bold;
}

.subheading {
    font-size: 32px;
}

.file-picker {

    margin-top: 30px;
    margin-bottom: 20px;
    width: 50%;
    height:250px;
    border: 2px solid gray;
    border-radius: 10px;
    text-align: center;
    font-size: 16px;
    color: gray;

    width: 50%;
    height: 250px;
    // border: 2px dashed gray;
    text-align: center;
    // font-size: 16px;
    color: gray;
  
    &-label {
      display: block;
    }
  
    &-placeholder {
      margin-bottom: 16px;
      font-size: 20px;
      margin-top:100px;
    }
  
    &-button {
      padding: 10px 20px;
      border: none;
      background-color: #007bff;
      color: white;
      font-size: 20px;
      border-radius: 10px;
      cursor: pointer;
      &:disabled {
        background-color: gray;
      }
    }
  }

 .spaceBetween {
    width : 100%;
    display: flex;
    justify-content: space-between;
 } 