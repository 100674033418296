.cardElementContainer {
    margin-bottom: 20px;
    border: 1px solid green;
    border-radius: 50px;
    padding : 10px
}
  
.submitButton {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: 2px solid #4CAF50; /* Add border */
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.submitButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
} 

.paymentElement {
    width: 100%;
    border: 1px solid black;
}