.root {
    // margin-bottom: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.size1 {
    font-size: 30px;
}

.size2 {
    color: #d84856;
    font-size: 16px;
    border-left: solid 3px #d84856;
    padding-left: 10px;
}

.spacing-normal {
    margin-bottom: 10px;
}

.spacing-middle {
    margin-top: 20px;
    margin-bottom: 20px;
}