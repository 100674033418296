.root {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.subRoot{
    width: 48.95%;
}

.flex{ 
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.buttonsStyle {
    display: flex;
    gap: 10px;
}