.root{
    width:100%;
}

.mainHead{
    color: #000;
    font-size: 2rem;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin: 4% 0px 40px 5%;
}

.paymentPlan {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 3.5px;
    margin-top: 1rem;
}

.card {
    position: relative;
    border: 1px solid #f4eeee;
    border-radius: 22px;
    background: #FFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    padding: 20px;
    height:26%;
    transition:background 2s;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
    -webkit-transition:background 2s;
    -moz-transition:background 2s;
    -ms-transition:background 2s;
    -o-transition:background 2s;
    .priceCard{
        display:grid;
        place-items:center;
    }
    .priceInfo{
        width: 45%;
        height: 44%;
        position: absolute;
        border-radius: 18px;
        background: #0B52C8;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        text-align: center;
        -webkit-border-radius: 18px;
        -moz-border-radius: 18px;
        -ms-border-radius: 18px;
        -o-border-radius: 18px;
        transition:background 2s;
        .priceHeading{
            color: #FFF;
            // font-family: Poppins SemiBold;
            font-size: 22px;
            font-weight: 700;
            line-height: normal;
            margin:1rem 0px 0px 0px;
            transition:color 2s;
        }
        .price{
            font-size:32px;
            margin:5px 0px 5px; 
            font-weight:700;
            color:white;
            transition:color 2s;
        }
        .paymentType{
            font-size:14px;
            margin:2px;
            color:white;
            transition:color 2s;
        }
    }
    .planDetails{
    position:relative;
    margin-top: 30%;
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color:#0B52C8;
    transition:color 2s;
    -webkit-transition:color 2s;
    -moz-transition:color 2s;
    -ms-transition:color 2s;
    -o-transition:color 2s;
}

}
.card:hover,
.card.selected {
  background-color: #0B52C8;
  cursor: pointer;
  .planDetails{
    color:white
  }
.priceInfo{
    background-color: #FFF;
    .priceHeading,.price,.paymentType{
        color: #0B52C8;
    }
}
}
.cardsBox{
    margin-top: 4%;
    padding: 3px;
    width: 100%;
}
.employeeCard{ 
    border-radius: 22px; 
    background: #FFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
     -webkit-border-radius: 22px;
     -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
}