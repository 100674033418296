.root {
    padding: 5px 0px;
    width: 100%;
    // margin-right: 10px;
    // margin-left: 10px;
}

.label {
    padding: 10px 0px;
    font-weight: bold;
}

.error {
    color: #EC5840;
}

.errorBox {
    color: #EC5840;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 5px;
}

.horizontal {
    display:flex;
    align-items: center;
    justify-content: space-between;

    & > *:not(:first-child) {
        margin-left: 5px;
    }

}